<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-top-transition"
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="default" v-bind="attrs" v-on="on">
        <v-icon>mdi-plus</v-icon>
        {{ $t('feedbacks.add') }}
      </v-btn>
    </template>

    <v-card>
      <v-toolbar color="secondary" dark>
        {{ $t('feedbacks.add') }}
      </v-toolbar>
      <v-card-text>
        <v-skeleton-loader
          v-if="loading"
          type="article, list-item, actions"
          :loading="true"
          class="mt-4"
        ></v-skeleton-loader>
        <div v-else class="pt-4">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="feedbackData.processType"
                  :items="feedbackTypes"
                  item-text="label"
                  item-value="value"
                  :label="$t('feedbacks.type')"
                  required
                  :rules="[(v) => v !== -1 || $t('validation.fieldRequired')]"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="feedbackData.formId"
                  :items="feedbackForms"
                  :label="$t('feedbacks.form')"
                  :disabled="feedbackData.processType === -1"
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true
                  }"
                  :rules="[(v) => !!v || $t('validation.fieldRequired')]"
                  required
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="feedbackData.donor"
                  :items="usersList"
                  :label="$t('feedbacks.donor')"
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true
                  }"
                  :rules="[(v) => !!v || $t('validation.fieldRequired')]"
                  return-object
                  required
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="feedbackData.mentor"
                  :items="usersList"
                  :label="$t('feedbacks.mentor')"
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true
                  }"
                  return-object
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>
      <v-card-actions v-if="!loading">
        <v-spacer></v-spacer>
        <v-btn text color="secondary" @click="dialog = false">
          {{ $t('close') }}
        </v-btn>

        <v-btn text color="secondary" @click="createFeedback">
          {{ $t('feedbacks.add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { feedbackEnums } from '@/enums/feedbacks.js';

import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    recipient: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      dialog: false,
      valid: true,

      allFeedbackTypes: [
        { value: feedbackEnums.processTypes.MAFEG, label: 'MAFEG' },
        { value: feedbackEnums.processTypes.PDP, label: 'PDP' },
        { value: feedbackEnums.processTypes.EHG, label: 'EHG' },
        { value: feedbackEnums.processTypes.PDC, label: 'PDC' },
        { value: feedbackEnums.processTypes.EDC, label: 'EDC' },
        { value: feedbackEnums.processTypes.TestPeriod, label: 'Probezeitgespräch' },
        { value: feedbackEnums.processTypes.PerformanceEvaluation, label: 'Leistungsbeurteilung' },
        { value: feedbackEnums.processTypes.OffboardingEvaluation, label: 'Offboarding' },
        { value: feedbackEnums.processTypes.CheckIn, label: 'Puls-CheckIn' }
      ],

      processList: [],

      feedbackData: {
        id: uuidv4(),
        processType: -1,
        recipientId: this.recipient.id,
        correlationId: uuidv4(),
        statusId: 0,
        archived: false,
        loading: true
      }
    };
  },

  computed: {
    ...mapState('forms', {
      availableForms: 'listItems'
    }),

    ...mapState({
      workflowSettings: 'workflowSettings'
    }),

    ...mapGetters('users', {
      getUsers: 'getOverviewUsers'
    }),

    loading() {
      return (
        this.availableForms === null ||
        this.availableForms === undefined ||
        this.getUsers === null ||
        this.getUsers === undefined ||
        this.getUsers.length === 0
      );
    },

    feedbackForms() {
      if (this.loading) {
        return [];
      }

      const self = this;

      return this.availableForms
        .filter((x) => x.type === self.feedbackData.processType)
        .map((x) => {
          return {
            value: x.id,
            text: x.name[0].translation
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    feedbackTypes() {
      if (this.processList.length === 0) {
        return [];
      }

      return this.allFeedbackTypes.filter(
        (x) => this.processList.indexOf(x.value) >= 0
      );
    },

    usersList() {
      if (this.loading) {
        return [];
      }

      return this.getUsers
        .filter((x) => x.id !== this.recipient.id)
        .map((x) => {
          return {
            text: `${x.firstname} ${x.lastname}`,
            value: x.id
          };
        })
        .sort((a, b) => {
          if (a.text.toLowerCase() < b.text.toLowerCase()) {
            return -1;
          } else if (a.text.toLowerCase() > b.text.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        });
    }
  },

  watch: {
    async dialog() {
      if (this.dialog === true) {
        await this.fetchUsers();
        await this.loadForms();
        this.processList = await this.listProcesses(this.recipient.id);
      }
    }
  },

  methods: {
    ...mapActions({
      loadForms: 'forms/listForms',
      fetchUsers: 'users/fetchUsers',
      listProcesses: 'feedbacks/listProcessTypes'
    }),

    async createFeedback() {
      await this.$refs.form.validate();

      if (this.valid) {
        this.feedbackData.statusId = this.getInitialStatus(
          this.feedbackData.processType
        );

        const dataCopy = JSON.parse(JSON.stringify(this.feedbackData));
        this.$emit('created', dataCopy);
        this.dialog = false;

        this.clearFormData();
      }
    },

    clearFormData() {
      this.$refs.form.reset();

      this.feedbackData = {
        id: uuidv4(),
        processType: -1,
        recipientId: this.recipient.id,
        correlationId: uuidv4(),
        statusId: 0
      };
    },

    getInitialStatus(processType) {
      const processWorkflow = this.workflowSettings.filter(
        (x) => x.processType === processType.toString()
      );

      if (processWorkflow.length === 0) {
        return 0;
      }

      return parseInt(processWorkflow[0].initialStatus);
    }
  }
};
</script>

<style></style>
